<template>
  <div
    class="px-4 py-2"
  >
    <p
      class="receiver w-fit"
    >
      Hello There!
    </p>
    <p
      class="receiver mt-2 w-fit"
    >
      How Can I Help You?
    </p>
  </div>
</template>

<style scoped lang="scss">

.receiver {
  @apply bg-[#EEEEEE] max-w-[75%] px-[14px] py-[10px]
  rounded-tr-[20px] rounded-bl-none rounded-br-[20px] rounded-tl-[20px];
}
</style>
