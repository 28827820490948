<template>
  <div class="mx-auto max-w-xl px-8 py-3 sm:px-10">
    <div
      class="text-center"
    >
      <h1 class="text-2xl font-semibold">
        {{ title }}
      </h1>
      <p
        class="mt-3"
      >
        Feel free to say anything you want to know or say. We’ll get back to you as soon as possible
      </p>
    </div>
    <Form
      #default="{ isSubmitting: isValidatingForm }"
      class="mx-auto my-6 pb-20 sm:px-4"
      :validationSchema="schema"
      :validationContext="schema"
      @submit="onSubmit"
    >
      <InputHelper
        ref="emailInputHelperRef"
        required
        name="email"
        :value="email"
        type="email"
        label="Your email"
        placeholder="Email Address"
      />
      <InputHelper
        name="phoneNo"
        type="text"
        label="Phone number [Ex: +880123456789]"
        :value="phoneNo"
        placeholder="[+][country code][local phone no]"
      />
      <TextAreaHelper
        name="message"
        type="text"
        label="What would you like to talk about?"
        :value="message"
        placeholder="Write your message"
      />
      <div
        class="w-full"
      >
        <button
          type="submit"
          class="btn w-full bg-blue-1 hover:bg-blue-2"
          :disabled="isSubmitting || isValidatingForm"
        >
          <span
            v-if="isSubmitting"
            class="loading loading-spinner loading-md"
          />
          Send
        </button>
      </div>
    </Form>
  </div>
</template>

<script lang="ts" setup>

import axios from 'axios';
import type {
  SendSupportMailDto,
} from 'dto';
import {
  Form,
  SubmissionContext,
} from 'vee-validate';
import {
  computed,
  onMounted,
  ref,
} from 'vue';
import {
  object,
  string,
} from 'yup';
import InputHelper from '@/components/Helper/InputHelper.vue';
import TextAreaHelper from '@/components/Helper/TextAreaHelper.vue';
import {
  handleAxiosError,
  supportMenuList,
} from '@/helpers';
import {
  useStore,
} from '@/store';

const utilStore = useStore.util;

let conversationId = '';

const email = ref('');
const phoneNo = ref('');
const message = ref('');

const emailInputHelperRef = ref<InstanceType<typeof InputHelper> | null>(null);

const title = computed(() => {
  const support = supportMenuList.find((item) => item.id === utilStore.supportId);
  return support ? support.text : '';
});

const isSubmitting = ref(false);

interface IFormSubmitValues {
  email: string;
  phoneNo: string;
  message: string;
}

const schema = computed(() => object().shape({
  email: string()
    .label('Email')
    .email()
    .required(),

  phoneNo: string()
    .label('Phone No')
    .nullable()
    .test('is-valid-phone', 'Valid E.164 phone no is required', (value) => {
      if (!value) {
        return true;
      }

      const trimmedValue = value.trim();
      return /^\+[1-9]\d{1,14}$/.test(trimmedValue);
    })
    .optional(),

  message: string()
    .label('Message'),
}));

async function onSubmit(
  values: IFormSubmitValues,
  submissionContext: SubmissionContext,
): Promise<void> {
  if (isSubmitting.value) {
    return;
  }

  try {
    isSubmitting.value = true;

    const payload: SendSupportMailDto = {
      email: values.email,
      ...(values.phoneNo && {
        phone: values.phoneNo,
      }),
      ...(values.message && {
        message: values.message.replace(/(?:\r\n|\r|\n)/g, '<br>'),
      }),
      ...(conversationId && {
        conversationId,
      }),
    };

    const response = await axios.post<{message: string}>('/support/mail', payload);

    useStore.util.notification = {
      type: 'success',
      message: response.data.message,
    };

    submissionContext.resetForm();
  } catch (e: any) {
    handleAxiosError(e);
  } finally {
    isSubmitting.value = false;
  }
}

function _focusEmailInput(): void {
  if (!emailInputHelperRef.value
  || !emailInputHelperRef.value.inputEl) {
    return;
  }

  const { inputEl } = emailInputHelperRef.value;

  inputEl.readOnly = true;
  inputEl.focus();

  setTimeout(() => {
    inputEl.readOnly = false;
  }, 50);
}

onMounted(() => {
  const { uuid } = useStore.conversationThread;

  if (uuid) {
    conversationId = uuid;
    useStore.conversationThread.uuid = '';
  }

  _focusEmailInput();
});

</script>

<style lang="css" scoped>

.btn {
    @apply bg-[#F8F8F8] rounded-lg px-4 py-1 my-1 shadow-md border-gray-400 w-full;
  }

.btn:hover {
    @apply bg-blue-1 text-white;
  }

</style>
