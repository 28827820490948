/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Oct 03 2023
 */

import {
  defineStore,
} from 'pinia';
import {
  IConversation,
  IConversationMessage,
} from '@/type';

export const useConversationThreadStore = defineStore('conversationThread', {
  state: () => ({
    uuid: '',
    templateId: '',

    isSocketConnected: false,
    isHistoryLoading: false,
    isReplyLoading: false,

    totalPage: 0,
    sentMessageCountAfterMount: 0,

    messages: [] as IConversationMessage[],
    streamingConversation: undefined as IConversation | undefined,
  }),
});
