/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Sep 24 2024
 */

<template>
  <div
    class="relative grow"
  >
    <div
      class="overflow-y-scroll"
      :class="[
        utilStore.notification ? 'h-90' : 'h-104',
      ]"
    >
      <EmailSupport
        v-if="utilStore.supportId === 3"
      />
      <CallOrMessageSupport
        v-else
      />
    </div>
  </div>
</template>

<script setup lang="ts">

import CallOrMessageSupport from '@/components/ChatInterface/ContactSupport/CallOrMessageSupport.vue';
import EmailSupport from '@/components/ChatInterface/ContactSupport/EmailSupport.vue';
import {
  useStore,
} from '@/store';

const utilStore = useStore.util;

</script>
