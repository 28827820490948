<template>
  <div class="app relative z-[2147483647]">
    <ChatIcon
      v-show="!utilStore.isChatOpen"
      @interface:open="openInterface"
    />
    <ChatApp
      v-if="isChatInterfaceLoaded"
      v-show="utilStore.isChatOpen"
      @interface:close="utilStore.isChatOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import {
  ref,
} from 'vue';
import {
  useStore,
} from '@/store';
import ChatApp from '@/views/ChatApp.vue';
import ChatIcon from '@/views/ChatIcon.vue';

const utilStore = useStore.util;

const isChatInterfaceLoaded = ref(false);

function openInterface(): void {
  isChatInterfaceLoaded.value = true;
  utilStore.isChatOpen = true;
}

</script>
