/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Sep 03 2024
 */

<template>
  <div
    v-if="conversationHistoryStore.showHistoryList"
    class="my-2 flex justify-center text-[#6B6B6B]"
  >
    <button
      type="button"
      class="btn flex gap-2 rounded-lg border-gray-400 bg-[#F8F8F8]
            px-4 py-2 shadow-md hover:bg-blue-1 hover:text-white"
      @click="onClickNewConversation"
    >
      <PhNotePencil :size="24" />
      <p>
        New Conversation
      </p>
    </button>
  </div>
  <div
    v-else
    class="sticky top-0 z-10 flex w-full justify-between"
  >
    <button
      type="button"
      class="history-list-btn"
      @click="handleShowHistoryList"
    >
      <PhSidebar
        :size="28"
        class="opacity-75"
      />
    </button>
    <button
      v-if="conversationThreadStore.uuid || utilStore.isSupportOn"
      type="button"
      class="create-new-btn"
      @click="onClickNewConversation"
    >
      <PhNotePencil
        :size="28"
        class="opacity-75"
      />
    </button>
  </div>
</template>

<script lang="ts" setup>

import {
  PhNotePencil,
  PhSidebar,
} from '@phosphor-icons/vue';
import {
  useStore,
} from '@/store';

const conversationHistoryStore = useStore.conversationHistory;
const conversationThreadStore = useStore.conversationThread;
const utilStore = useStore.util;

function handleShowHistoryList(): void {
  conversationHistoryStore.showHistoryList = true;
  utilStore.isSupportOn = false;
  utilStore.supportId = -1;
}

function onClickNewConversation(): void {
  utilStore.isSupportOn = false;
  utilStore.supportId = -1;

  conversationHistoryStore.showHistoryList = false;
  utilStore.isSupportOn = false;
  conversationThreadStore.messages = [];
  conversationThreadStore.uuid = '';
}

</script>

<style scoped lang="scss">

.history-list-btn, .create-new-btn {
  @apply m-2 cursor-pointer rounded-sm bg-gray-100
  p-1 shadow-md hover:bg-gray-300;
}

</style>
