import axios, {
  AxiosError,
} from 'axios';
import type {
  CreateUserDto,
} from 'dto';
import {
  defineStore,
} from 'pinia';
import {
  useUtilStore,
} from './util';
import {
  CONSTANTS,
} from '@/helpers/constants';
import {
  ICompany,
  ICreateUserResponse,
  IUser,
} from '@/type';

const defaultAuthErrorMsg = 'Sorry, Chat assistant is not available right now';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    loading: false,
    token: '',
    userId: undefined as string | undefined,
    company: undefined as undefined | Pick<ICompany, 'contactSettings'>,
  }),
  actions: {
    _setErrorMessage(message = defaultAuthErrorMsg): void {
      useUtilStore().notification = {
        message,
        type: 'error',
        permanent: true,
      };
    },
    _setAuthInfo(data: ICreateUserResponse): void {
      const { TOKEN, USER_ID } = CONSTANTS.STORAGE;
      const { userId, token, company } = data;

      this.userId = userId;
      this.token = token;
      this.company = company;

      localStorage.setItem(TOKEN, this.token);
      localStorage.setItem(USER_ID, this.userId);

      axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    },
    async _createUser(userId: string | undefined): Promise<void> {
      this.loading = true;

      try {
        const payload: CreateUserDto = {
          website: window.location.origin,
          ...(userId && {
            userId,
          }),
        };

        const response = await axios.post<ICreateUserResponse>('/auth', payload);

        this._setAuthInfo(response.data);
      } catch (e: any) {
        console.error('_createUser error: ', e);

        const axiosMessage = e?.isAxiosError
          ? (e as AxiosError<{message: string}>).response?.data.message
          : undefined;

        this._setErrorMessage(axiosMessage);
      }

      this.loading = false;
    },
    async getUser(): Promise<string | undefined> {
      const { USER_ID, TOKEN } = CONSTANTS.STORAGE;

      const userId = localStorage.getItem(USER_ID) || undefined;
      const token = localStorage.getItem(TOKEN);

      if (typeof userId !== 'string' || typeof token !== 'string') {
        await this._createUser(userId);
        return this.userId;
      }

      this.loading = true;

      try {
        const resp = await axios.get<{
          user: Pick<IUser, '_id'> | null,
          company: Pick<ICompany, 'contactSettings'>| null
        }>(`/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { user, company } = resp.data;

        if (!user || !company) {
          this._setErrorMessage();
          return this.userId;
        }

        this._setAuthInfo({
          userId,
          token,
          company,
        });
      } catch (e: any) {
        console.error('getUser error: ', e);

        if (e?.isAxiosError && (e as AxiosError).response?.status === 401) {
          await this._createUser(userId);
        } else {
          this._setErrorMessage();
        }
      }

      this.loading = false;

      return this.userId;
    },
  },
});
