<template>
  <div
    class="flex w-full rounded-lg bg-[#EEEEEE] p-2 xsm:px-4"
  >
    <div
      class="mr-1 w-3/4 xsm:mr-0"
    >
      <p
        v-if="createdAt"
        class="my-1 text-xs text-gray-500"
      >
        {{ dayjs(createdAt).format('lll') }}
      </p>
      <input
        ref="titleEditInput"
        v-model="inputText"
        type="text"
        class="input-1 h-9 w-[90%] xsm:w-full"
        :disabled="isLoading"
      >
    </div>

    <div
      class="mt-6 flex w-1/4 items-center justify-end"
    >
      <span
        v-if="isLoading"
        class="loading loading-spinner loading-md mx-auto"
      />
      <div
        v-else
        class="flex gap-x-2 font-medium"
      >
        <button
          type="button"
          class="rounded-full border-2 p-2 text-gray-400"
          :class="{ 'bg-blue-1 text-white hover:bg-blue-2': inputText }"
          :disabled="!inputText"
          @click="$emit('confirm', inputText)"
        >
          <CheckIcon
            class="size-4"
          />
        </button>
        <button
          type="button"
          class="rounded-full border-1 border-gray-500 p-2 hover:bg-red-500"
          @click="$emit('cancel')"
        >
          <XMarkIcon
            class="size-4"
          />
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  CheckIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  onMounted,
  ref,
} from 'vue';

dayjs.extend(localizedFormat);

defineEmits<{
  (e: 'confirm', value: string): void;
  (e: 'cancel'): void;
}>();

const props = defineProps({
  createdAt: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const titleEditInput = ref<HTMLInputElement | null>(null);

const inputText = ref<string>(props.text);

onMounted(() => {
  titleEditInput.value?.focus();
});

</script>
