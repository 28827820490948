<template>
  <div
    class="relative size-full px-1"
  >
    <div
      v-for="(message, messageIndex) in conversationThreadStore.messages"
      :key="message.content + messageIndex"
      class="flex items-center overflow-hidden p-2 md:px-4"
      :class="{
        'justify-start': message.type === 'receiver',
        'justify-end': message.type === 'sender',
      }"
    >
      <div
        class="text-md break-words font-normal"
        :class="{
          receiver: message.type === 'receiver',
          sender: message.type === 'sender',
        }"
      >
        <p
          v-if="message.type === 'receiver'"
          v-html="formattedResponse(message.content)"
        />
        <p
          v-else
        >
          {{ message.content }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
} from 'vue';
import {
  useStore,
} from '@/store';

const conversationThreadStore = computed(() => useStore.conversationThread);

function formattedResponse(response: string): string {
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Replace URL with an HTML anchor tag
  const clickableResponse = response.replace(
    urlRegex,
    (url) => `<a 
      href="${url}"
      class="text-blue-600 underline px-1 rounded-md hover:bg-gray-300" 
      target="_blank"
    >
      ${url}
    </a></br>`,
  );

  return clickableResponse;
}

</script>

<style scoped lang="scss">

.btn {
  @apply bg-white text-red-500 border border-red-500 rounded-lg px-4 py-2 m-2;
}

.btn:hover {
  @apply bg-red-500 text-white;
}

.receiver {
  @apply bg-[#EEEEEE] max-w-[75%] px-[14px] py-[10px]
  rounded-tr-[20px] rounded-bl-none rounded-br-[20px] rounded-tl-[20px];
}

.sender {
  @apply bg-[#5688D2] max-w-[75%] px-[14px] py-[10px] text-white rounded-tr-[20px]
  rounded-bl-[20px] rounded-br-none rounded-tl-[20px];
}

</style>
