<template>
  <div class="mx-auto max-w-xl p-8 py-3 md:px-18">
    <div
      class="text-center"
    >
      <h1 class="text-2xl font-semibold">
        {{ title }}
      </h1>
      <p>
        Feel free to contact us at the {{ id === 2 ? 'email' : 'mobile number' }} provided.
      </p>
    </div>
    <div
      class="relative mt-7"
    >
      <input
        v-if="id === 2"
        type="text"
        readonly
        :value="companyEmail"
        class="mb-3 w-full rounded-md border py-3 pl-4 pr-8 text-gray-700
        shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
      />
      <input
        v-else
        type="text"
        readonly
        :value="companyPhoneNo"
        class="mb-3 w-full rounded-md border py-3 pl-4 pr-8 text-gray-700
        shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
      />
      <div
        v-if="isTextCopied"
        class="tooltip tooltip-bottom absolute -right-4 top-3 flex items-center pr-6 text-green-500"
        data-tip="Copied"
      >
        <PhCheck
          class="size-5"
        />
      </div>
      <button
        v-else
        type="button"
        class="absolute -right-4 top-4 flex items-center pr-6  hover:text-red-500"
        @click="handleCopyText"
      >
        <PhCopy
          class="size-5"
        />
      </button>
    </div>
    <a
      :href="id === 1 ? `tel:${companyPhoneNo}` : `mailto:${companyEmail}`"
      class="btn flex items-center justify-center"
    >
      {{ id === 1 ? 'Call Now' : 'Send Mail' }}
    </a>
  </div>
</template>

<script lang="ts" setup>
import {
  PhCheck,
  PhCopy,
} from '@phosphor-icons/vue';
import {
  computed,
  ref,
} from 'vue';
import {
  copyToClipboard,
  supportMenuList,
} from '@/helpers';
import {
  useStore,
} from '@/store';

const authStore = useStore.auth;
const utilStore = useStore.util;

const id = computed(() => utilStore.supportId);
const companyEmail = computed(() => authStore.company?.contactSettings?.email);
const companyPhoneNo = computed(() => authStore.company?.contactSettings?.phoneNo);
const isTextCopied = ref(false);

const title = computed(() => {
  const support = supportMenuList.find((item) => item.id === id.value);
  return support ? support.text : '';
});

let copyButtonClickTimeout: ReturnType<typeof setTimeout> | undefined;

async function handleCopyText(): Promise<void> {
  const text = id.value === 1 ? companyPhoneNo.value : companyEmail.value;
  await copyToClipboard(text as string);

  if (copyButtonClickTimeout) {
    clearTimeout(copyButtonClickTimeout);
    copyButtonClickTimeout = undefined;
  }

  isTextCopied.value = true;

  copyButtonClickTimeout = setTimeout(() => {
    isTextCopied.value = false;
  }, 1000 * 1);
}
</script>

<style lang="css" scoped>

.btn {
    @apply bg-[#F8F8F8] rounded-lg px-4 py-1 my-1 shadow-md border-gray-400;
  }

.btn:hover {
    @apply bg-blue-1 text-white;
  }

</style>
