/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Sep 24 2024
 */

<template>
  <div
    class="relative grow"
  >
    <CustomLoader1
      v-if="conversationHistoryStore.isLoading"
      class="flex h-full"
    />
    <template
      v-else
    >
      <div
        class="relative"
        :class="utilStore.notification ? 'h-92' : 'h-104'"
      >
        <!-- conversation message body start -->
        <div
          ref="conversationDiv"
          class="h-full"
          :class="{ 'overflow-y-auto': hasThreadMsg }"
        >
          <CustomLoader1
            v-if="conversationThreadStore.isHistoryLoading"
            class="flex h-full"
          />
          <div
            v-else-if="!hasThreadMsg"
          >
            <InstructionMsgs />
            <InstructionActions />
          </div>

          <!-- conversation thread start -->
          <div
            v-if="hasThreadMsg"
            class="relative h-auto"
          >
            <div
              class="mt-3"
            >
              <InfiniteScroll
                @load="loadConversations"
              >
                <template
                  #bottom
                >
                  <MessageList />
                </template>
              </InfiniteScroll>
            </div>
          </div>
        <!-- conversation thread end -->
        </div>
        <!-- conversation message body end -->
        <!-- Chat input start -->
        <div
          ref="chatInputDiv"
          class="absolute bottom-0 w-full bg-white"
        >
          <ChatInput
            class="px-4"
            @input="adjustConversationHeight"
          />
        </div>
      <!-- Chat input end -->
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>

import {
  computed,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import ChatInput from '@/components/ChatInterface/ChatThread/ChatInput.vue';
import InstructionActions from '@/components/ChatInterface/ChatThread/InstructionActions.vue';
import InstructionMsgs from '@/components/ChatInterface/ChatThread/InstructionMsgs.vue';
import MessageList from '@/components/ChatInterface/ChatThread/MessageList.vue';
import InfiniteScroll from '@/components/Helper/InfiniteScroll.vue';
import CustomLoader1 from '@/components/Helper/Loader/CustomLoader1.vue';
import {
  type ConversationThreadService,
  getConversationThreadService,
} from '@/services/ConversationThreadService';
import {
  useStore,
} from '@/store';

type ScrollBehavior = 'auto' | 'instant' | 'smooth';

let conversationThreadService: ConversationThreadService;

const utilStore = useStore.util;
const conversationThreadStore = useStore.conversationThread;
const conversationHistoryStore = useStore.conversationHistory;
const createdBefore = new Date().toISOString();

const hasThreadMsg = computed(() => !!conversationThreadStore.messages.length);

const conversationDiv = ref<HTMLDivElement | null>(null);
const chatInputDiv = ref<HTMLDivElement | null>(null);

let prevChatInputHeight = 0;
let currentConversationPage = 0;

async function loadConversations(): Promise<void> {
  if (!conversationThreadStore.uuid || !utilStore.isHistoryChat) {
    return;
  }

  const earlierConversationScrollHeight = conversationDiv.value?.scrollHeight || 0;

  const { loadCount } = await conversationThreadService
    .loadPreviousConversations(currentConversationPage + 1, createdBefore);

  if (loadCount) {
    const totalConversationScrollHeight = conversationDiv.value?.scrollHeight || 0;

    // go close to earlier position
    _scrollConversationMessage('instant', totalConversationScrollHeight - earlierConversationScrollHeight);
    currentConversationPage += 1;
  }
}

function adjustConversationHeight(): void {
  if (!conversationDiv.value || !chatInputDiv.value) {
    return;
  }

  const chatInputHeight = chatInputDiv.value.offsetHeight;
  const conversationHeight = conversationDiv.value.offsetHeight;

  if (chatInputHeight !== prevChatInputHeight) {
    const heightDifference = chatInputHeight - prevChatInputHeight;
    conversationDiv.value.style.height = `calc(${conversationHeight}px - ${heightDifference}px)`;
  }

  prevChatInputHeight = chatInputHeight || 0;
}

function _scrollConversationMessage(
  behavior: ScrollBehavior,
  top = conversationDiv.value?.scrollHeight,
): void {
  conversationDiv.value?.scrollTo({
    behavior,
    top,
  });
}

watch(() => utilStore.notification === undefined, () => {
  if (conversationDiv.value) {
    conversationDiv.value.style.height = '360px';
  }
});

watch(() => conversationThreadStore.messages, () => {
  if (!conversationThreadStore.isReplyLoading || !conversationThreadStore.messages.length) {
    return;
  }

  if (conversationDiv.value) {
    conversationDiv.value.style.height = '360px';
  }

  // reply is being added with conversation message, go to bottom
  _scrollConversationMessage('instant');
});

watch(() => conversationThreadStore.isReplyLoading, (x, y) => {
  // if reply loading starts or ends, go to bottom of conversation
  adjustConversationHeight();
  setTimeout(() => {
    _scrollConversationMessage('instant');
  }, 1000 * 0.25);
});

onMounted(async () => {
  conversationThreadService = getConversationThreadService();

  loadConversations();
  adjustConversationHeight();
});

onUnmounted(() => {
  utilStore.isHistoryChat = false;
});

</script>
