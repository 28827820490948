<template>
  <div>
    <ChatNavigation />
    <!-- v-show is used so that history is always present in dom. Which ensures history api is not called every time -->
    <ChatHistoryUi
      v-show="conversationHistoryStore.showHistoryList"
    />

    <template
      v-if="!conversationHistoryStore.showHistoryList"
    >
      <ContactSupportUi
        v-if="utilStore.isSupportOn"
      />

      <ChatThreadUi
        v-else
      />
    </template>
  </div>
</template>

<script lang="ts" setup>

import {
  onMounted,
} from 'vue';
import ChatHistoryUi from '@/components/ChatInterface/ChatHistory/ChatHistoryUi.vue';
import ChatNavigation from '@/components/ChatInterface/ChatNavigation.vue';
import ChatThreadUi from '@/components/ChatInterface/ChatThread/ChatThreadUi.vue';
import ContactSupportUi from '@/components/ChatInterface/ContactSupport/ContactSupportUi.vue';
import {
  type ConversationThreadService,
  getConversationThreadService,
} from '@/services/ConversationThreadService';
import {
  useStore,
} from '@/store';

let conversationThreadService: ConversationThreadService;

const utilStore = useStore.util;
const conversationHistoryStore = useStore.conversationHistory;

onMounted(async () => {
  conversationThreadService = getConversationThreadService();
});

</script>

<style lang="scss">

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

</style>
