<template>
  <div
    class="mb-4 mt-6 flex flex-col items-center text-center"
  >
    <button
      type="button"
      class="btn"
      @click="sendMessage('List all products of the company')"
    >
      Learn about our products
    </button>
    <button
      type="button"
      class="btn"
      @click="sendMessage('Provide info about the company')"
    >
      Know about our company
    </button>
    <button
      type="button"
      class="btn"
      @click="sendMessage('How can I contact with company?')"
    >
      How can I contact you?
    </button>
  </div>
</template>

<script setup lang="ts">
import {
  onMounted,
} from 'vue';
import {
  ConversationSocketService,
  getConversationSocketService,
} from '@/services/ConversationSocketService';
import {
  useStore,
} from '@/store';

let conversationSocketService: ConversationSocketService;

const conversationThreadStore = useStore.conversationThread;

function sendMessage(query: string): void {
  if (conversationThreadStore.isReplyLoading) {
    return;
  }

  conversationSocketService.sendMessage(query);
}

onMounted(() => {
  conversationSocketService = getConversationSocketService();
});

</script>

<style lang="css" scoped>

.btn {
    @apply bg-[#F8F8F8] rounded-lg px-2 sm:px-4 my-1 py-1 shadow-md border-gray-400
    text-xs sm:text-sm w-52 sm:w-60 md:w-64;
  }

.btn:hover {
    @apply bg-blue-1 text-white;
  }

</style>
