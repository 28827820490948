<template>
  <div class="flex items-center justify-center border-t py-1">
    <span class="my-auto text-xs text-gray-400">
      Powered by
    </span>
    <a
      class="pl-2"
      href="https://nerddevs.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        :src="require('@/assets/nerddevs_orange_logo.png')"
        alt="nerddevs-logo"
      >
    </a>
  </div>
</template>
