import axios from 'axios';
import {
  EditConversationTitleDto,
} from 'dto';
import {
  download,
  handleAxiosError,
} from '@/helpers';
import {
  IAppUseStore,
  useStore,
} from '@/store';
import {
  IGetConversationsData,
} from '@/type';

let conversationHistoryService: ConversationHistoryService | undefined;
let conversationHistoryStore: IAppUseStore['conversationHistory'];
let utilStore: IAppUseStore['util'];

interface IGetConversationHistoryParams {
  pageSize: number;
  pageNo: number;
}

const INITIAL_HISTORY_LIMIT = 10;

export class ConversationHistoryService {
  userId: string;

  constructor() {
    this.userId = useStore.auth.userId as string;
  }

  private async _fetchConversationHistory(
    pageNo: number,
    pageSize: number,
    createdBefore?: string,
  ): Promise<IGetConversationsData> {
    let fetchedConversationResponse: IGetConversationsData = {
      conversations: [],
      totalData: 0,
    };

    const { userId } = useStore.auth;
    if (!userId) {
      return fetchedConversationResponse;
    }

    try {
      conversationHistoryStore.isLoading = true;

      const params: IGetConversationHistoryParams = {
        pageSize,
        pageNo,
        ...(createdBefore && {
          createdBefore,
        }),
      };

      const response = await axios.get<IGetConversationsData>(
        `/conversations/list/user/${userId}`,
        {
          params,
        },
      );

      fetchedConversationResponse = response.data;
    } catch (e: any) {
      handleAxiosError(e);
    } finally {
      conversationHistoryStore.isLoading = false;
    }

    return fetchedConversationResponse;
  }

  async loadConversationHistory(createdBefore?: string): Promise<void> {
    if (conversationHistoryStore.isLoading || !conversationHistoryStore.hasMore) {
      return;
    }

    try {
      const fetchedConversationResponse = await this._fetchConversationHistory(
        conversationHistoryStore.currentPage,
        INITIAL_HISTORY_LIMIT,
        createdBefore,
      );

      const { conversations, totalData } = fetchedConversationResponse;

      if (conversations.length) {
        conversationHistoryStore.conversationList.push(...conversations);

        const { conversationList, newChatCountAfterMount } = conversationHistoryStore;

        const loadedBackendHistoryData = Math.abs(conversationList.length - newChatCountAfterMount);

        conversationHistoryStore.hasMore = loadedBackendHistoryData < totalData;

        if (conversationHistoryStore.hasMore) {
          conversationHistoryStore.currentPage += 1;
        }

        conversationHistoryStore.showHistoryList = true;
      } else {
        conversationHistoryStore.hasMore = false;
      }
    } catch (e: any) {
      handleAxiosError(e);
    }
  }

  async updateTitle(conversationId: string, newTitle: string): Promise<void> {
    try {
      conversationHistoryStore.isLoading = true;

      const payload: EditConversationTitleDto = {
        title: newTitle,
        userId: this.userId,
      };

      await axios.put(`/conversations/${conversationId}/title`, payload);

      // Update the title in the store's state
      const conversation = conversationHistoryStore.conversationList.find(
        (c) => c._id === conversationId,
      );
      if (conversation) {
        conversation.title = newTitle;
      }

      utilStore.notification = {
        message: 'Successfully updated conversation title.',
        type: 'success',
      };
    } catch (e: any) {
      handleAxiosError(e, 'Error in updating conversation title.');
    } finally {
      conversationHistoryStore.isLoading = false;
    }
  }

  async exportConversation(
    conversationId: string,
  ): Promise<void> {
    try {
      conversationHistoryStore.isLoading = true;

      const response = await axios.get(
        `/conversations/export/user/${this.userId}?conversationId=${conversationId}`,
        {
          responseType: 'blob',
        },
      );

      const contentDisposition = response.headers['content-disposition'];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);

      if (!matches || !matches[1]) {
        utilStore.notification = {
          message: 'Error in retrieving filename. Download aborted.',
          type: 'error',
        };
        return;
      }

      const filename = matches[1].replace(/['"]/g, '');

      download(new Blob([response.data]), filename);
    } catch (e: any) {
      handleAxiosError(e, 'Error in exporting conversation.');
    } finally {
      conversationHistoryStore.isLoading = false;
    }
  }
}

export function getConversationHistoryService(): ConversationHistoryService {
  if (conversationHistoryService) {
    return conversationHistoryService;
  }

  conversationHistoryStore = useStore.conversationHistory;
  utilStore = useStore.util;

  conversationHistoryService = new ConversationHistoryService();

  return conversationHistoryService;
}
