/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Sep 24 2024
 */

<template>
  <div
    v-show="conversationHistoryStore.showHistoryList"
    class="my-2 h-full"
  >
    <ChatHistoryList
      :class="[
        utilStore.notification ? 'h-87' : 'h-99',
        { 'overflow-y-scroll': conversationHistoryStore.conversationList.length > 2 },
      ]"
      @conversation:id="onSingleHistoryClick($event)"
    />

    <CustomLoader1
      v-if="conversationHistoryStore.isLoading"
    />
  </div>
</template>

<script setup lang="ts">

import {
  onMounted,
} from 'vue';
import ChatHistoryList from '@/components/ChatInterface/ChatHistory/ChatHistoryList.vue';
import CustomLoader1 from '@/components/Helper/Loader/CustomLoader1.vue';
import {
  type ConversationThreadService,
  getConversationThreadService,
} from '@/services/ConversationThreadService';
import {
  useStore,
} from '@/store';

let conversationThreadService: ConversationThreadService;

const utilStore = useStore.util;
const conversationHistoryStore = useStore.conversationHistory;
const conversationThreadStore = useStore.conversationThread;

async function onSingleHistoryClick(
  conversationId: string,
): Promise<void> {
  conversationThreadService.clearHistoryAssociatedFields();

  // history chat
  utilStore.isHistoryChat = true;
  conversationThreadStore.uuid = conversationId;
  conversationHistoryStore.showHistoryList = false;
}

onMounted(async () => {
  conversationThreadService = getConversationThreadService();
});

</script>
