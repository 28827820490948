<template>
  <div>
    <div
      class="flex items-center justify-between
    rounded-lg px-4 py-2 shadow-lg"
      :class="{
        'bg-[#FFF9EB] text-[#A6813D]': type === 'warning',
        'bg-[#EEFBF2] text-[#458563]': type === 'success',
        'bg-[#FDF1F5] text-[#E13452]': type === 'error',
      }"
    >
      <p>{{ message }}</p>
      <button
        v-if="!permanent"
        type="button"
        class="rounded-full p-1 hover:bg-red-300"
        @click="$emit('close')"
      >
        <XMarkIcon
          class="size-6"
        />
      </button>
    </div>
    <div
      v-if="permanent"
      class="overflow-hidden"
    >
      <img
        :src="require('@/assets/unauthorized.png')"
        alt="unauthorized access"
        class="mx-auto mt-2 h-100"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import {
  PropType,
  onMounted,
  onUnmounted,
} from 'vue';

const props = defineProps({
  message: {
    type: String,
    required: true,
  },
  type: {
    type: String as PropType<'success' | 'error' | 'warning'>,
    required: true,
  },
  permanent: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits<{
  (e: 'close'): void;
}>();

let notificationTimeout: ReturnType<typeof setTimeout> | undefined;

function _clearTimeout(): void {
  if (!notificationTimeout) {
    return;
  }

  clearTimeout(notificationTimeout);
  notificationTimeout = undefined;
}

function _addTimeout(): void {
  if (props.permanent) {
    return;
  }

  notificationTimeout = setTimeout(() => {
    emits('close');
  }, 5000);
}

onMounted(() => {
  _clearTimeout();
  _addTimeout();
});

onUnmounted(() => {
  _clearTimeout();
});

</script>
