import {
  defineStore,
} from 'pinia';

interface INotification {
  message: string;
  type: 'success' | 'error' | 'warning';
  permanent?: boolean;
}

export const useUtilStore = defineStore('util', {
  state: () => ({
    isChatOpen: false,
    isSupportOn: false,
    isLeftNavigationMenuOn: false,
    supportId: -1,
    notification: undefined as undefined | INotification,
    isHistoryChat: false,
  }),
});
