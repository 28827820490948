<template>
  <div
    class="fixed bottom-5 right-5 z-10 flex h-[580px] w-10/12 max-w-md flex-col
    rounded-lg border bg-white text-sm shadow-lg md:w-full md:text-base"
  >
    <div
      class="relative"
    >
      <FullContentButton
        v-if="isLeftNavigationMenuOn"
        class="z-10"
        @click="utilStore.isLeftNavigationMenuOn = !utilStore.isLeftNavigationMenuOn"
      />
      <ChatTitle
        @close="emits('interface:close')"
      />
      <main>
        <div
          class="relative"
        >
          <AppNotification
            v-if="notification"
            :message="notification.message"
            :type="notification.type"
            :permanent="notification.permanent"
            @close="utilStore.notification = undefined"
          />

          <CustomLoader1
            v-if="authStore.loading"
            class="my-auto"
          />
          <ChatInterface
            v-else-if="authStore.userId"
            :class="{ 'opacity-50': isLeftNavigationMenuOn }"
          />
        </div>
      </main>
    </div>
    <ChatFooter
      class="absolute bottom-0 w-full"
    />
  </div>
</template>

<script setup lang="ts">

import {
  computed,
  onMounted,
} from 'vue';
import ChatFooter from '@/components/ChatApp/ChatFooter.vue';
import ChatInterface from '@/components/ChatApp/ChatInterface.vue';
import ChatTitle from '@/components/ChatApp/ChatTitle.vue';
import AppNotification from '@/components/Helper/AppNotification.vue';
import FullContentButton from '@/components/Helper/FullContentButton.vue';
import CustomLoader1 from '@/components/Helper/Loader/CustomLoader1.vue';
import {
  useStore,
} from '@/store';

const emits = defineEmits<{
  (e: 'interface:close'): void;
}>();

const authStore = useStore.auth;
const utilStore = useStore.util;

const notification = computed(() => utilStore.notification);
const isLeftNavigationMenuOn = computed(() => utilStore.isLeftNavigationMenuOn);

onMounted(async () => {
  authStore.getUser();
});

</script>
