<template>
  <div
    v-if="!conversationHistoryStore.conversationList.length"
    class="mx-auto mt-3 w-8/12"
  >
    <img
      :src="require('@/assets/empty.png')"
      alt="empty chat history"
    />
    <p
      class="text-center text-lg font-bold"
    >
      Empty chat history!
    </p>
  </div>
  <div
    v-else
    class="mt-3"
  >
    <InfiniteScroll
      @load="loadConversationHistory"
    >
      <template
        #top
      >
        <div
          v-for="conversation in conversationHistoryStore.conversationList"
          :key="conversation._id"
          class="w-full px-3 py-1"
        >
          <EditTitleForm
            v-if="editConversationId === conversation._id"
            :createdAt="conversation.createdAt"
            :text="conversation.title"
            :isLoading="isTitleUpdating"
            @confirm="(value: string) => onEditConfirm(conversation._id, value, conversation.title)"
            @cancel="onEditCancel"
          />
          <div
            v-else
            class="flex w-full items-center justify-between rounded-lg bg-[#EEEEEE] shadow-sm"
          >
            <button
              type="button"
              class="w-full overflow-hidden px-4 py-2 text-start hover:rounded-lg hover:bg-[#d8d8d8]"
              @click="emits('conversation:id', conversation._id)"
            >
              <p
                v-if="conversation.createdAt"
                class="my-1 text-xs text-gray-500"
              >
                {{ dayjs(conversation.createdAt).format('lll') }}
              </p>
              <h1
                class="text-md truncate text-left font-medium"
              >
                {{ conversation.title }}
              </h1>
            </button>
            <button
              type="button"
              class="mx-2 flex items-center justify-center p-3 hover:rounded-full hover:bg-[#c5c5c5]"
              :class="{ hidden: isEditing(conversation._id) }"
              @click="onSingleHistoryMenuClick(conversation._id, $event)"
            >
              <EllipsisVerticalIcon
                v-if="!isMenuOpen(conversation._id) && !isEditing(conversation._id)"
                class="size-5"
              />
              <XMarkIcon
                v-if="isMenuOpen(conversation._id) && !isEditing(conversation._id)"
                class="size-5 text-gray-500"
              />
            </button>
          </div>
          <!-- Dropdown menu related stuffs -->
          <div class="relative">
            <button
              v-if="isMenuOpen(conversation._id)"
              type="button"
              class="fixed inset-0"
              @click="toggleMenu(conversation._id)"
            />
            <ul
              v-if="isMenuOpen(conversation._id)"
              class="menu menu-lg absolute right-0 z-10 mt-1 w-46 rounded-md bg-base-100 shadow-lg"
              :class="{ 'bottom-full mb-2': shouldOpenUpwards }"
            >
              <li>
                <button
                  type="button"
                  class="flex w-full items-center justify-start gap-2 p-2 text-left text-sm"
                  @click="onEdit(conversation._id)"
                >
                  <PencilIcon
                    class="size-4"
                  />
                  Edit title
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="flex w-full items-center justify-start gap-2 p-2 text-left text-sm"
                  @click="onExportConversation(conversation._id)"
                >
                  <ArrowTopRightOnSquareIcon
                    class="size-4"
                  />
                  Export conversation
                </button>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </InfiniteScroll>
    <div
      v-if="conversationHistoryStore.isLoading"
      class="my-1 flex items-center justify-center"
    >
      <span
        class="loading loading-spinner loading-md"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  ArrowTopRightOnSquareIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  onMounted,
  ref,
  watch,
} from 'vue';
import EditTitleForm from '@/components/ChatInterface/ChatHistory/EditTitleForm.vue';
import InfiniteScroll from '@/components/Helper/InfiniteScroll.vue';
import CustomLoader1 from '@/components/Helper/Loader/CustomLoader1.vue';
import {
  type ConversationHistoryService,
  getConversationHistoryService,
} from '@/services/ConversationHistoryService';
import {
  useStore,
} from '@/store';

dayjs.extend(localizedFormat);

const emits = defineEmits<{
  (e: 'conversation:id', value: string): void;
}>();

const conversationHistoryStore = useStore.conversationHistory;
const utilStore = useStore.util;

let conversationHistoryService: ConversationHistoryService;
const historyInitialLoadAt: string = new Date().toISOString();

const shouldOpenUpwards = ref(false);

const editConversationId = ref('');
const openedMenuId = ref<null | string>(null);

const isTitleUpdating = ref(false);

watch(
  [(): boolean => conversationHistoryStore.showHistoryList,
    (): boolean => utilStore.isChatOpen],
  ([newShowHistoryList, newIsChatOpen]) => {
    if (newShowHistoryList || newIsChatOpen) {
      editConversationId.value = '';
    }
  },
);

function checkMenuPosition(event: MouseEvent): void {
  const menuHeight = 200;
  const distanceFromBottom = window.innerHeight - event.clientY;
  shouldOpenUpwards.value = distanceFromBottom < menuHeight;
}

function onSingleHistoryMenuClick(conversationId: string, event: MouseEvent): void {
  checkMenuPosition(event);

  if (!isEditing(conversationId)) {
    toggleMenu(conversationId);
  }
}

function isMenuOpen(conversationId: string): boolean {
  return openedMenuId.value === conversationId;
}

function isEditing(conversationId: string): boolean {
  return editConversationId.value === conversationId;
}

function onEdit(id: string): void {
  editConversationId.value = id;
  openedMenuId.value = null; // Close the dropdown when clicking Edit
}

function onEditCancel(): void {
  editConversationId.value = '';
}

async function onEditConfirm(
  conversationId: string,
  newTitle: string,
  prevTitle: string,
): Promise<void> {
  const trimmedTitle = newTitle.trim();

  // Check if the title is empty or unchanged
  if (trimmedTitle && trimmedTitle !== prevTitle) {
    isTitleUpdating.value = true;
    await conversationHistoryService.updateTitle(conversationId, trimmedTitle);
  }

  editConversationId.value = '';
  openedMenuId.value = null; // Close the dropdown after confirming edit

  isTitleUpdating.value = false;
}

function toggleMenu(conversationId: string): void {
  if (openedMenuId.value === conversationId) {
    openedMenuId.value = null;
  } else {
    openedMenuId.value = conversationId;
  }
}

function onExportConversation(conversationId: string): void {
  conversationHistoryService.exportConversation(conversationId);
  openedMenuId.value = null;
}

async function loadConversationHistory(): Promise<void> {
  await conversationHistoryService.loadConversationHistory(historyInitialLoadAt);
}

onMounted(() => {
  conversationHistoryService = getConversationHistoryService();
  loadConversationHistory();
});
</script>
